<template>
  <el-dialog width="70%" :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Forma de Pagamento:</h4>
      </el-row>
      <el-select v-model="orderPayment_.payment_method" size="medium">
        <el-option
          v-for="(item, index) in PaymentMethod"
          :key="index"
          :label="item"
          :value="index"
        >
        </el-option>
      </el-select>
      <el-row
        type="flex"
        justify="space-between"
        v-if="orderPayment_.payment_method in CanCreateRelatedDocument"
      >
        <h4>Cadastrar {{ PaymentMethod[orderPayment_.payment_method] }}:</h4>
        <el-switch
          active-text="Sim"
          inactive-text="Não"
          v-model="orderPayment_.createRelatedDocument"
          :disabled="!order.invoice?.nfe"
        ></el-switch>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Valor:</h4>
      </el-row>
      <BaseInput type="money" hint="R$" v-model="orderPayment_.amount">
      </BaseInput>
      <el-row type="flex" justify="start">
        <h4>Vencimento:</h4>
      </el-row>
      <el-date-picker
        type="datetime"
        size="medium"
        format="DD/MM/YYYY"
        v-model="orderPayment_.expires_at"
      />
      <el-row type="flex" justify="start">
        <h4>Pago em:</h4>
      </el-row>
      <el-date-picker
        :disabled="true"
        type="datetime"
        size="medium"
        format="DD/MM/YYYY"
        v-model="orderPayment_.paid_at"
      />
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import OrderService from "../../services/orders";
import BilletService from "../../services/billets";
import { notifySuccess } from "../../utils/notifiers";

export default {
  props: ["order", "payment", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      isLoadingSave: false,
      orderPayment_: this.payment || {},
    };
  },
  watch: {
    payment(v) {
      this.orderPayment_ = v || {};
    },
    showModal(v) {
      if (v) this.orderPayment_.createRelatedDocument = false;
    },
  },
  computed: {
    PaymentMethod() {
      return {
        cash: "Crediário (no prazo)",
        money: "Dinheiro (a vista)",
        credit: "Cartão de Crédito (a vista)",
        debit: "Cartão de Débito (a vista)",
        billet: "Boleto (no prazo)",
        check: "Cheque (a vista)",
        pix: "PIX (a vista)",
      };
    },
    CanCreateRelatedDocument() {
      return {
        billet: true,
        Cheque: true,
      };
    },
    isNew() {
      return !(this.payment && "uid" in this.payment);
    },
    ModalTitle() {
      return !this.isNew
        ? "Editar pagamento de pedido"
        : "Cadastrar pagamento de pedido";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      this.isNew ? this.createPayment() : this.updatePayment();
    },
    shouldCreateBillet() {
      return (
        !!this.getFirstClientBankAccount() &&
        this.orderPayment_?.createRelatedDocument &&
        this.orderPayment_?.payment_method === "billet"
      );
    },
    async createPayment() {
      const { payment } = await OrderService(this.order.uid)
        .Payments()
        .create(this.orderPayment_);

      if (payment) {
        notifySuccess(payment.message);

        if (this.shouldCreateBillet()) await this.createBillet(payment.uid);

        this.$emit("close-modal");
        this.$emit("should-update");
      }
    },
    async updatePayment() {
      const { payment } = await OrderService(this.order.uid)
        .Payments(this.orderPayment_.uid)
        .update(this.orderPayment_);

      if (payment) {
        notifySuccess(payment.message);
        this.$emit("close-modal");
        this.$emit("should-update");
      }
    },
    getFirstClientBankAccount() {
      return this.order?.client?.bank_accounts?.at(0);
    },
    async createBillet(orderPaymentId) {
      if (this.getFirstClientBankAccount()) {
        const { billet } = await BilletService().create({
          firm_bank_account_id: this.getFirstClientBankAccount().uid,
          partner_id: this.order.client.uid,
          order_payments_id: orderPaymentId,
          order_id: this.order.uid,
          expires_at: this.orderPayment_.expires_at,
          amount: this.orderPayment_.amount,
        });

        return billet;
      }

      return null;
    },
  },
  name: "OrderPaymentModal",
};
</script>
<style scoped>
.mx-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}

.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}

.payment-order {
  font-weight: 600;
  height: 41px;
  line-height: 41px;
}
</style>
